import { useInfiniteQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import logger from "../../utils/logger";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

const useGetRecipes = (
  searchValue?: string,
  sortBy?: string,
  page = 1,
  enabled = true
) => {
  const { getAccessTokenSilently } = useAuthentication();

  const {
    isLoading,
    data,
    isError,
    isSuccess,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [queryKeys.recipes, searchValue, sortBy],
    async ({ pageParam = 1 }) => {
      await getAccessTokenSilently();
      return apiClient.getRecipes(
        searchValue,
        sortBy,
        pageParam,
        undefined,
        undefined,
        undefined
      );
    },
    {
      // getNextPageParam: (lastPage, allPages) => {
      //   console.log(lastPage, allPages);
      //   const nextPage =
      //     lastPage.length === 5 ? allPages.length + 1 : undefined;
      //   return nextPage;
      // },
      getNextPageParam: (lastPage, allPages) => {
        // This assumes the API returns an empty array when there's no more data
        //we want to say, if the lastPage.length is greater than 25 - our pagination amount - then we want to say there's a next page param.
        //If not, dont show it .
        return lastPage.length >= 30 ? allPages.length + 1 : undefined;
      },

      onSuccess: (data) => {},

      onError: (error: any) => {
        logger.error(
          { error /*...authData, isAuthenticated */ },
          "Error retrieving getRecipes"
        );
        toast.error(
          `Status: ${error.status}- We ran into an error! Please try again - ${error}`
        );
      },
      enabled,
    }
  );

  return {
    isLoading,
    isError,
    data,
    isSuccess,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useGetRecipes;
